$( document ).ready(function() {

  $('.popup-cook .glyphicon-remove').on('click',function(){
    $('.popup-cook .div-center').fadeOut();
    $('.popup-cook .glyphicon-remove').fadeOut();
    $('.popup-cook .pu-overlay-cook').fadeOut();
  });

  $('.popup-cond .glyphicon-remove').on('click',function(){
    $('.popup-cond .div-center').fadeOut();
    $('.popup-cond .glyphicon-remove').fadeOut();
    $('.popup-cond .pu-overlay-cond').fadeOut();
  });

  $('.popup-cook .pu-overlay-cook').on('click',function(){
    $('.popup-cook .div-center').fadeOut();
    $('.popup-cook .glyphicon-remove').fadeOut();
    $('.popup-cook .pu-overlay-cook').fadeOut();
  });

  $('.popup-cond .pu-overlay-cond').on('click',function(){
    $('.popup-cond .div-center').fadeOut();
    $('.popup-cond .glyphicon-remove').fadeOut();
    $('.popup-cond .pu-overlay-cond').fadeOut();
  });
  
  $('#span-pop-up-cook').on('click', function(){
    $('.pu-overlay-cook').fadeIn("fast");
    $('.popup-cook .div-center').fadeIn("fast");
    $('.popup-cook .glyphicon-remove').fadeIn("fast");
  });

  $('#span-pop-up-cond').on('click', function(){
    $('.pu-overlay-cond').fadeIn("fast");
    $('.popup-cond .div-center').fadeIn("fast");
    $('.popup-cond .glyphicon-remove').fadeIn("fast");
  });

  $(document).keyup(function(event){
    if(event.which==27) {
      $('.popup-cook .div-center').fadeOut();
      $('.popup-cook .glyphicon-remove').fadeOut();
      $('.popup-cook .pu-overlay-cook').fadeOut();
    }

    if(event.which==27) {
      $('.popup-cond .div-center').fadeOut();
      $('.popup-cond .glyphicon-remove').fadeOut();
      $('.popup-cond .pu-overlay-cond').fadeOut();
    }
  });
});
